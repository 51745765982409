
import { api } from "@/api/api";
import { ApiGetApplicationFormTemplateDto } from "@/api/generated/Api";
import BaseModalForm from "@/components/shared/modal/BaseModalForm.vue";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { NotificationItemType } from "@/shared/enums/notificationItemEnum";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { openNotification } from "@/shared/helpers/store.helpers";
import { getValidatableRef } from "@/shared/helpers/typeHelpers";
import { useStore } from "@/shared/useHelpers";
import { StoreState } from "@/store/store.state.interface";
import { defineComponent, PropType, ref } from "@vue/composition-api";
import { validateNotEmpty } from "@/shared/helpers/validationHelpers";

export default defineComponent({
  name: "ApplicationFormEditModal",
  components: { BaseModalForm },
  props: {
    modalType: {
      type: String as PropType<ModalType>,
      required: true,
    },
    headline: {
      type: String,
      required: true,
    },
    existingItemModal: {
      type: Object as PropType<ApiGetApplicationFormTemplateDto>,
      required: true,
    },
  },
  setup(props, { emit, refs }) {
    const store = useStore<StoreState>();
    const name = ref(props.existingItemModal?.name ?? "Ukjent navn");
    const isActive = ref(props.existingItemModal?.isActive ?? false);
    const handleSubmit = () => {
      const isValid = getValidatableRef(refs.connectForm)?.validate();
      if (props.modalType !== ModalType.Edit) {
        openNotification(store, NotificationItemType.Error, "Ukjent ModalType, dette skal aldri skje");
        return;
      }
      if (!isValid) {
        return;
      }
      globalLoadingWrapper({ blocking: true }, async () => {
        await api.appformtemplate.updateAppFormTemplate(props.existingItemModal.id, {
          isActive: isActive.value,
          name: name.value,
        });
        openNotification(store, NotificationItemType.Success, "Ny status er lagret");
        emit("close");
      });
    };
    return { isActive, handleSubmit, name, validateNotEmpty };
  },
});
