var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseModalForm', {
    attrs: {
      "headline": _vm.headline,
      "modalType": _vm.modalType
    },
    on: {
      "submit": _vm.handleSubmit,
      "close": function close($event) {
        return _vm.$emit('close');
      }
    }
  }, [_c('v-form', {
    ref: "connectForm",
    attrs: {
      "lazy-validation": ""
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('v-text-field', {
    attrs: {
      "data-cy": "name",
      "label": "Navn for ".concat(_vm.existingItemModal.isVocationalSchool ? 'søknadskjemaet' : 'påmeldingsskjemaet'),
      "rules": _vm.validateNotEmpty,
      "required": ""
    },
    model: {
      value: _vm.name,
      callback: function callback($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1)], 1), _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "6",
      "sm": "6"
    }
  }, [_vm._v(" Du kan kun endre om den er aktiv eller ikke: "), _c('v-switch', {
    attrs: {
      "data-cy": "isActive",
      "label": _vm.isActive ? 'Aktiv' : 'Inaktiv',
      "color": "primary",
      "inset": "",
      "hide-details": ""
    },
    model: {
      value: _vm.isActive,
      callback: function callback($$v) {
        _vm.isActive = $$v;
      },
      expression: "isActive"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }