var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.modalDataEdit.showModal ? _c('BaseModal', {
    on: {
      "close": function close($event) {
        return _vm.closeModal(_vm.modalDataEdit);
      }
    }
  }, [_c('ApplicationFormEditModal', {
    attrs: {
      "headline": _vm.modalDataEdit.modalHeadline,
      "modalType": _vm.modalDataEdit.modalType,
      "existingItemModal": _vm.modalDataEdit.existingItem
    },
    on: {
      "close": function close($event) {
        return _vm.closeModal(_vm.modalDataEdit);
      }
    }
  })], 1) : _vm._e(), _vm.modalDataDelete.showModal ? _c('BaseModal', {
    on: {
      "close": function close($event) {
        return _vm.closeModal(_vm.modalDataDelete);
      }
    }
  }, [_c('ApplicationFormDeleteModal', {
    attrs: {
      "headline": _vm.modalDataDelete.modalHeadline,
      "modalType": _vm.modalDataDelete.modalType,
      "existingItemModal": _vm.modalDataDelete.existingItem
    },
    on: {
      "close": function close($event) {
        return _vm.closeModal(_vm.modalDataDelete);
      }
    }
  })], 1) : _vm._e(), _c('BaseLayout', {
    staticClass: "px-0",
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          attrs: {
            "data-cy": "titleApplicationForm"
          }
        }, [_vm._v(" Påmeldingsskjema ")])];
      },
      proxy: true
    }, {
      key: "actions",
      fn: function fn() {
        return [_c('v-text-field', {
          staticClass: "mr-5",
          attrs: {
            "append-icon": "mdi-magnify",
            "label": "Søk",
            "single-line": "",
            "clearable": "",
            "hide-details": ""
          },
          model: {
            value: _vm.search,
            callback: function callback($$v) {
              _vm.search = $$v;
            },
            expression: "search"
          }
        }), _c('v-btn', {
          attrs: {
            "color": "white",
            "outlined": "",
            "data-cy": "open-new-application-form-popup"
          },
          on: {
            "click": _vm.openNewApplicationPage
          }
        }, [_c('v-icon', {
          attrs: {
            "left": ""
          }
        }, [_vm._v("mdi-plus")]), _vm._v(" Nytt påmeldingsskjema ")], 1)];
      },
      proxy: true
    }])
  }, [[_c('BaseTableFiltered', {
    staticClass: "mt-3",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.applicationForms,
      "search": _vm.search,
      "sort-by": "updated",
      "sort-desc": true
    },
    scopedSlots: _vm._u([{
      key: "item.isActive",
      fn: function fn(_ref) {
        var value = _ref.value;
        return [_vm._v(" " + _vm._s(value ? "Aktiv" : "Inaktiv") + " ")];
      }
    }, {
      key: "item.isVocationalSchool",
      fn: function fn(_ref2) {
        var value = _ref2.value;
        return [_vm._v(" " + _vm._s(value ? "Ja" : "Nei") + " ")];
      }
    }, {
      key: "item.updated",
      fn: function fn(_ref3) {
        var value = _ref3.value;
        return [_vm._v(" " + _vm._s(_vm.formatRelative(value)) + " ")];
      }
    }, {
      key: "actions",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref5) {
              var on = _ref5.on,
                  attrs = _ref5.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                staticClass: "mr-2",
                attrs: {
                  "data-cy": "openDisplayApplicationPage"
                },
                on: {
                  "click": function click($event) {
                    return _vm.openDisplayApplicationPage(item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-clipboard-text-search-outline ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Vis " + _vm._s(item.name))])]), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref6) {
              var on = _ref6.on,
                  attrs = _ref6.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                staticClass: "mr-2",
                attrs: {
                  "data-cy": "openEditApplicationForm"
                },
                on: {
                  "click": function click($event) {
                    return _vm.openEditApplicationForm(item, item.name);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-pencil ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Rediger " + _vm._s(item.name))])]), item.appliedInCourses && item.appliedInCourses.length <= 0 ? _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref7) {
              var on = _ref7.on,
                  attrs = _ref7.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                staticClass: "mr-2",
                attrs: {
                  "data-cy": "openDeleteApplicationForm"
                },
                on: {
                  "click": function click($event) {
                    return _vm.openDeleteApplicationForm(item, item.name);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-delete ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Slett " + _vm._s(item.name))])]) : _vm._e()];
      }
    }], null, true)
  })]], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }