
import { api } from "@/api/api";
import { ApiGetApplicationFormTemplateDto } from "@/api/generated/Api";
import ApplicationFormDeleteModal from "@/components/administration/application-form/ApplicationFormDeleteModal.vue";
import ApplicationFormEditModal from "@/components/administration/application-form/ApplicationFormEditModal.vue";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { LoadingType } from "@/shared/enums/loading-type.enum";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { AdminRouteNames } from "@/shared/enums/RouteNames/adminRouteNamesEnum";
import { formatRelative } from "@/shared/helpers/dateHelpers";
import { globalLoadingWrapper } from "@/shared/helpers/loadingHelpers";
import { ModalBaseData } from "@/shared/interfaces/ModalBaseData.interface";
import { useRouter } from "@/shared/useHelpers";
import { defineComponent, onMounted, ref } from "@vue/composition-api";

export default defineComponent({
  name: "ApplicationFormPage",
  components: {
    BaseTableFiltered,
    BaseModal,
    BaseLayout,
    ApplicationFormEditModal,
    ApplicationFormDeleteModal,
  },
  setup() {
    const applicationForms = ref<ApiGetApplicationFormTemplateDto[]>([]);
    const search = ref<string>("");
    const modalDataEdit = ref(getInitialModalData());
    const modalDataDelete = ref(getInitialModalData());
    const router = useRouter();

    const loadApplications = async () => {
      globalLoadingWrapper({ type: LoadingType.SkeletonTable }, async () => {
        applicationForms.value = (await api.appformtemplate.getAppFormTemplatesAsync()).data;
      });
    };

    const openEditApplicationForm = useOpenModal(ModalType.Edit, "påmeldingskjema", modalDataEdit);
    const openDeleteApplicationForm = useOpenModal(ModalType.Delete, "påmeldingskjema", modalDataDelete);

    const closeModal = (modalData: ModalBaseData) => {
      modalData.showModal = false;
      loadApplications();
    };

    const openNewApplicationPage = () => {
      router.push({
        name: AdminRouteNames.ApplicationsCreate,
      });
    };

    const openDisplayApplicationPage = (item: ApiGetApplicationFormTemplateDto) => {
      router.push({
        name: AdminRouteNames.ApplicationsView,
        params: {
          applicationId: item.id,
        },
      });
    };

    onMounted(() => {
      loadApplications();
    });

    return {
      applicationForms,
      headers,
      search,

      openEditApplicationForm,
      openDeleteApplicationForm,
      openNewApplicationPage,
      openDisplayApplicationPage,
      modalDataEdit,
      modalDataDelete,
      closeModal,
      formatRelative,
    };
  },
});

const headers = [
  { text: "Navn", value: "name" },
  { text: "Fagskole", value: "isVocationalSchool" },
  { text: "Sist Oppdatert", value: "updated" },
  { text: "Aktiv/Inaktiv", value: "isActive" },
  { text: "Handlinger", value: "actions", sortable: false },
];
